import { AdminSpace, AdminSpaceMap } from "gather-admin-common/dist/src/public/spaces/types";
import { getErrorMessage } from "gather-common/dist/src/public/utils";
import HttpV2AdminClient from "../../http-client/httpV2Client";
import { HttpV2Paths } from "gather-http-common/dist/src/public/httpAPI";
import { SpaceUserResource } from "gather-common/dist/src/public/resources/space";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";
import { RecordingPrisma } from "gather-prisma-types/dist/src/public/client";
import { just } from "gather-common/dist/src/public/fpHelpers";
import { SpaceRecordingBudgetInfo } from "gather-common/dist/src/public/resources/recording";

export const fetchSpaceCapacity = async (spaceId: string) => {
  try {
    const response = await HttpV2AdminClient.get<{ maxCapacity: number }>(
      HttpV2Paths.AdminSpaceCapacity,
      {
        auth: true,
        params: { path: { space: spaceId } },
      },
    );

    return response?.maxCapacity;
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve space capacity."));
  }
};

export const fetchSpace: (spaceId: string | undefined) => Promise<AdminSpace | undefined> = async (
  spaceId,
) => {
  if (!spaceId) throw new Error("Missing spaceId");

  try {
    const maxCapacity = await fetchSpaceCapacity(spaceId);
    const space = await HttpV2AdminClient.get<AdminSpace>(HttpV2Paths.AdminSpace, {
      auth: true,
      params: { path: { space: spaceId } },
    });
    return space ? { ...space, maxCapacity } : undefined;
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve space."));
  }
};

export const fetchSpaceMaps: (spaceId: string | undefined) => Promise<AdminSpaceMap[]> = async (
  spaceId,
) => {
  if (!spaceId) throw new Error("Missing spaceId");

  try {
    const spaceMaps = await HttpV2AdminClient.get<AdminSpaceMap[]>(HttpV2Paths.AdminSpaceMaps, {
      auth: true,
      params: { path: { space: spaceId } },
    });
    return spaceMaps || [];
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve maps for space."));
  }
};

export const fetchSpaceMap: (
  spaceId?: string,
  mapId?: string,
) => Promise<AdminSpaceMap | undefined> = async (spaceId, mapId) => {
  if (!spaceId) throw new Error("Missing spaceId");

  if (!mapId) throw new Error("Missing mapId");

  try {
    return await HttpV2AdminClient.get<AdminSpaceMap>(HttpV2Paths.AdminSpaceMap, {
      auth: true,
      params: { path: { space: spaceId, map: mapId } },
    });
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve map."));
  }
};

export const fetchSpaceReservations: (
  spaceId: string | undefined,
) => Promise<AdminReservation[]> = async (spaceId) => {
  if (!spaceId) throw new Error("Missing spaceId");

  try {
    const spaceReservations = await HttpV2AdminClient.get<AdminReservation[]>(
      HttpV2Paths.AdminSpaceReservations,
      {
        auth: true,
        params: { path: { space: spaceId } },
      },
    );
    return spaceReservations || [];
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve reservations."));
  }
};

export const fetchSpaceUsers: (
  spaceId: string | undefined,
) => Promise<SpaceUserResource[]> = async (spaceId) => {
  if (!spaceId) throw new Error("Missing spaceId");

  try {
    const spaceUsers = await HttpV2AdminClient.get<SpaceUserResource[]>(
      HttpV2Paths.AdminSpaceUsers,
      {
        auth: true,
        params: { path: { space: spaceId } },
      },
    );
    return spaceUsers || [];
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve users."));
  }
};

/**
 * API request to toggle Gather staff access to a space
 * TODO @alecia - transform into the updateSpace request
 * @param spaceId - ID of the space being updated
 * @returns Promise
 */
export const toggleStaffAccess: (
  spaceId: string | undefined,
  // fields: SpaceUpdate, // TODO @alecia - uncomment eventually
) => Promise<void> = async (spaceId) => {
  if (!spaceId) throw new Error("Missing spaceId");

  try {
    return await HttpV2AdminClient.patch(HttpV2Paths.AdminSpace, {
      auth: true,
      params: { path: { space: spaceId } },
    });
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to toggle staff access."));
  }
};

/**
 * API request to update space trial data
 * @param spaceId - ID of the space being updated
 * @param endsAt - ISO string of trial expiry (or null to clear)
 * @returns Promise
 */
export const updateSpaceTrial: (
  spaceId: string | undefined,
  endsAt: string | null,
) => Promise<void> = async (spaceId, endsAt) => {
  if (!spaceId) throw new Error("Missing spaceId");

  try {
    return await HttpV2AdminClient.put(HttpV2Paths.AdminSpaceTrial, {
      auth: true,
      params: { path: { space: spaceId }, body: { endsAt } },
    });
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to update space trial."));
  }
};

/**
 * API request to delete space trial data
 * @param spaceId - ID of the space
 * @returns Promise
 */
export const deleteSpaceTrial: (spaceId: string | undefined) => Promise<void> = async (spaceId) => {
  if (!spaceId) throw new Error("Missing spaceId");

  try {
    return await HttpV2AdminClient.delete(HttpV2Paths.AdminSpaceTrial, {
      auth: true,
      params: { path: { space: spaceId } },
    });
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to delete space trial."));
  }
};

export const invokeGrapesForUser = async (userId: string, spaceId: string): Promise<void> => {
  try {
    return await HttpV2AdminClient.post(HttpV2Paths.AdminSpaceUserGrapes, {
      auth: true,
      params: { path: { space: spaceId, user: userId } },
    });
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to invoke grapes for user."));
  }
};

export const generateMeetingRecordingDownloadLink = async (
  spaceId: string,
  userId: string,
  recordingId: string,
  expiresIn: number,
): Promise<string> => {
  try {
    const recording = await HttpV2AdminClient.post<
      RecordingPrisma & {
        s3URL: string;
      }
    >(HttpV2Paths.AdminSpaceUserRecording, {
      auth: true,
      params: {
        path: { space: spaceId, user: userId, recording: recordingId },
        body: { expires: expiresIn },
      },
    });
    return just(recording).s3URL;
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to generate recording URL for recording."));
  }
};

export const getSpaceRecordingBudgetInfo = async (
  spaceId: string | undefined,
): Promise<SpaceRecordingBudgetInfo> => {
  if (!spaceId) throw new Error("Missing spaceId");
  try {
    return await HttpV2AdminClient.get<{
      hasValidPaidPlanForRecording: boolean;
      recordingTimeUsedMs: number;
      recordingBudgetMs: number;
      currentSubscriptionStart: string;
    }>(HttpV2Paths.AdminSpaceRecordingBudget, {
      auth: true,
      params: {
        path: {
          space: spaceId,
        },
      },
    });
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to get recording budget info."));
  }
};
